import React from "react"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

import Layout from "../../components/Layout"
import { Heading, Featured } from "../../components/utils"
import { Posts, Categories } from "../../components/magazine"
import { formatDate } from "../../utils/formatDate"

const Index = ({ data }) => {
  const featured = data.featured.nodes[0]

  const imageTags = [
    {
      tagName: "meta",
      attributes: {
        property: "og:image",
        content: featured && featured.seoImage && featured.seoImage.url,
      },
    },
    {
      tagName: "meta",
      attributes: {
        name: "twitter:image",
        content: featured && featured.seoImage && featured.seoImage.url,
      },
    },
  ]

  const metaTags = {
    tags: data.category.seoMetaTags.tags.concat(imageTags),
  }

  return (
    <Layout transparentHeader={!!featured}>
      <HelmetDatoCms
        htmlAttributes={{
          lang: data.category.locale,
        }}
        seo={metaTags}
      >
        {data.category._allSlugLocales.map((version, index) => (
          <link rel="alternate" hreflang={version.locale} href={`https://ikeys-realty.com/${version.locale}/magazine/${version.value}`} key={index} />
        ))}
      </HelmetDatoCms>
      <div className="category">
        {featured && 
          <Featured
            data={featured}
            title={featured.title}
            subtitle={
              <>
                {formatDate(featured.date, featured.locale)}
                <span className="mx-2">·</span>
                {featured.category.name}
              </>
            }
            image={featured.image.fluid}
          />
        }
        <div className="container py-12 md:py-24">
          <Categories categories={data.categories} />
          <Heading title={data.category.name} className="mb-12" />
          <Posts posts={data.posts.nodes} featured={featured} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CategoryQuery($locale: String!, $slug: String!) {
    category: datoCmsCategory(locale: { eq: $locale }, slug: { eq: $slug }) {
      ...Category
    }
    featured: allDatoCmsPost(
      filter: {
        locale: { eq: $locale }
        category: { slug: { eq: $slug } }
        featured: { eq: true }
        slug: { ne: null }
      }
      sort: { fields: meta___createdAt, order: DESC }
      limit: 1
    ) {
      nodes {
        ...Post
      }
    }
    posts: allDatoCmsPost(
      filter: {
        locale: { eq: $locale }
        category: { slug: { eq: $slug } }
        slug: { ne: null }
      }
      sort: { fields: meta___createdAt, order: DESC }
    ) {
      nodes {
        ...Post
      }
    }
    categories: allDatoCmsCategory(filter: { locale: { eq: $locale } }) {
      nodes {
        ...Category
      }
    }
  }
`

export default Index
