import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import { Locale } from "../../context"
import { linkResolver } from "../../utils/linkResolver"

export const Categories = ({ categories }) => {
  let { locale } = useContext(Locale)

  const magazine = useStaticQuery(graphql`
    query CategoriesQuery {
      en: datoCmsMagazine(locale: { eq: "en" }) {
        ...Magazine
      }
      fr: datoCmsMagazine(locale: { eq: "fr" }) {
        ...Magazine
      }
    }
  `)

  return (
    <div className="hidden mb-8 -mx-4 md:flex md:flex-wrap md:items-center">
      <div className="px-4 text-gray-400">
        <Link
          to={linkResolver(magazine[locale])}
          activeClassName="text-black"
          className="block mb-3 transition duration-200 hover:text-black"
        >
          {magazine[locale].all}
        </Link>
      </div>
      {categories.nodes.map((item, index) => (
        <div className="px-4 text-gray-400" key={index}>
          <Link
            to={linkResolver(item)}
            activeClassName="text-black"
            className="block mb-3 transition duration-200 hover:text-black"
          >
            {item.name}
          </Link>
        </div>
      ))}
    </div>
  )
}
