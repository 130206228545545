export const formatDate = (date, locale) => {
  // Format date
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  return new Date(date).toLocaleDateString(locale, options)
}
