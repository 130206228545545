import React, { useContext } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import { linkResolver } from "../../utils/linkResolver"
import { formatDate } from "../../utils/formatDate"
import { Locale } from "../../context"

export const Posts = ({ posts, featured }) => {
  let { locale } = useContext(Locale)

  return (
    <>
      <div className="lg:flex lg:flex-wrap lg:-mx-4">
        {posts
          .filter(x =>
            !featured ? true : x.originalId !== featured.originalId
          )
          .map((item, key) => (
            <div className="mb-6 lg:w-1/3 lg:px-4 lg:mb-8" key={key}>
              <Link to={linkResolver(item)}>
                <div className="h-full overflow-hidden bg-white border border-gray-200 rounded-lg shadow-lg">
                  <Img
                    fluid={item.image.fluid}
                    style={{
                      height: 224,
                      width: "100%",
                    }}
                    alt={item.title}
                  />
                  <div className="p-6">
                    <h3 className="text-xl font-semibold text-gray-900 font-headline">
                      {item.title}
                    </h3>
                    <p className="mt-1 text-gray-500 text-md">
                      {formatDate(item.date, locale)}
                      <span className="mx-2">·</span>
                      {item.category.name}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
    </>
  )
}
